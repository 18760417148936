.searchBox {
  padding: 0 !important;
}
.searchBox .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.amountSummary {
  padding-left: 12px;
}
.amountSummary span {
  color: red;
}
.orderDetail .money {
  color: red;
}
.orderDetail .orderStatus {
  font-weight: 800;
  font-size: 16px;
  margin-left: 10px;
}
.orderDetail .order_success {
  padding: 10px;
  background-color: #70b602;
  color: #fff;
}
.orderDetail .order_form {
  margin-top: 15px;
}
.orderDetail .order_form div.df {
  padding: 8px 0;
}
.orderDetail .order_form div.df span:first-child {
  text-align: left;
}
.orderDetail .order_form div.df .money {
  color: red;
}
.divider {
  margin: 12px 0;
}
.orderBox div {
  width: 50%;
  align-items: center;
}
