


















































































































































































































































































































































































.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.amountSummary{
    padding-left:12px;
    span{
        color:red;
    }
}
.orderDetail {
  .money {
    color: red;
  }
  .orderStatus {
    font-weight: 800;
    font-size: 16px;
    margin-left: 10px;
  }
  .order_success {
    padding: 10px;
    background-color: #70b602;
    // border: 1px solid rgb(255, 204, 0);
    color: #fff;
  }
  .order_form {
    margin-top: 15px;
    div.df {
      padding: 8px 0;
      span:first-child {
        // width: 6rem;
        text-align: left;
      }
      .money {
        color: red;
      }
    }
  }
}
.divider {
  margin: 12px 0;
}
.orderBox {
  div {
    width: 50%;
    align-items: center;
  }
}
